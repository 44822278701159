.dropdown {
    &.active {
        ul {
            display: block;
        }

        .fa-angle-down {
            transform: rotate(180deg); /* Equal to rotateZ(45deg) */
        }
    }

    .fa-angle-down {
        height: 14px;
        width: 14px;
        transition: all .3s;
        top: 2px;
        position: relative;
        margin-left: 5px;
    }

    ul {
        position: absolute;
        margin: 0;
        background-color: #FFF;
        border: 1px solid #aca6a0;
        z-index: 200;
        text-align: left;
        padding: .75rem;
        padding: 0;
        line-height: 1;
        display: none;
        left: 1rem;

        @include breakpoint(large) {
            left: auto;
        }

        li {
            min-width: 300px;



            a {
                &:hover {
                    background-color: #f7fbfc;
                }
            }
        }

        svg {
            position: absolute;
            right: 5px;
            width: 12px;
            height: 12px;
            margin-right: 6px;
            color: #03465c;
            transition: color .3s;
        }
    }
}
