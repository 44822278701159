/// <reference path="../app.scss" />

/// Related links block
/// @group comp
.related-links {
    margin-bottom: $global-margin*1.5;

    .box-content {

        .detail-row {
            h4, h3 {
                font-weight: 300;
                margin-bottom: 4px;
            }
        }

        .related-links-list {
            margin: 0;
            padding: 0;
            list-style: none;

            svg {
                width:1em;
                height:1em;
            }

            .doc-wrapper {
                position: relative;

                svg {
                    position: absolute;
                    top: 5px;
                }

                a {
                    padding-left: 20px;
                }
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
            }

            li {
                position: relative;
                padding: 0;
                margin-bottom: $global-margin/2;

                svg {
                    position: absolute;
                    top: 5px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            a {
                position: relative;
                display: block;
                font-size: 14px;
                // text-overflow: ellipsis;
                //white-space: nowrap;
                max-width: 100%;
                padding-left: 20px;
                display: inline-block;
                position: relative;
                font-weight: 300;
                overflow-wrap: break-word;
                word-break: break-word;
               
                &:after {
                    content: "";
                    height: 1px;
                    width: calc(100% - 20px);
                    position: absolute;
                    bottom: 0;
                    left: 20px;
                    background-color: $comp-green;
                    opacity: 0;
                    transition: all .2s;
                }

                &:hover {

                    &:after {
                        opacity: 1;
                    }
                }
            }

            &.doc-content-row {
                margin-bottom: $global-margin;
            }
        }
    }
}
