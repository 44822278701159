/// <reference path="../../app.scss" />

/// Primary button style
/// @group btn
.btn {
    background-color: $primary-color;
    color: $white;
    display: block;
    text-align: center;
    font-size: 14px;
    text-align: center;
    font-weight: 700;
    padding: $global-padding/1.5;
    text-transform: uppercase;
    margin-top: $global-margin*2;
    transition: background-color .2s;
    position: relative;
    border: 0;

    &:hover {
        color: $white;
        background-color: darken($primary-color, 5%);
    }

    &.tabbed {
        @include tabbedStyle();
        color: $white;
        outline-color: $primary-color;
    }
    &:focus {
        color:#FFF;
    }

    &.btn-white {
        background-color: $white;
        border: 1px solid $light-blue;
        color: $medium-blue;

        &:hover {
            background-color: darken($white, 5%);
        }
    }

    &.btn-green {
        background-color: $comp-green;
        border: 1px solid $black;
        color: $black;

        &:hover {
            background-color: darken($comp-green, 5%);
        }
    }

    &.btn-skyblue {
        background-color: $sky-blue;
        border: 1px solid $light-blue;
        color: $medium-blue;

        &:hover {
            background-color: darken($sky-blue, 5%);
        }
    }

    &.btn-nomargin {
        margin: 0;
    }

    &.btn-fullwidth {
        width:100%;
    }

    &.tabbed {
        outline-offset:2px;
    }
}

/// Small button style
/// @group btn
.btn-small {
    font-size: 12px;
    padding: $global-padding/2;

    svg {
        width: 0.75em;
        height: 0.75em;
    }
}

/// Quick button style
/// @group btn
.btn-quick {
    height: 45px;
    margin: 0;
    position: relative;
    border: none;
    padding-left: 46px;
    line-height: 22.5px;
    border-radius: 30px;
    padding-right: 22px;
    box-shadow: 1px 1px 2px rgba($black,.45);
    color: $offwhite;
    text-transform: uppercase;
    font-size: 13px;
    font-size: calc(1vw -3px );
    width: 100%;

    @include breakpoint(medium) {
        width: 90%;
    }

    .page-section & {
        margin-bottom: $global-margin;
    }

    @keyframes pulse-heart {
        0% {
            box-shadow: 0 0 0 0 rgba(231, 66, 36, 0.6);
        }

        70% {
            box-shadow: 0 0 0 15px rgba(231, 66, 36, 0);
        }

        100% {
            box-shadow: 0 0 0 20px rgba(231, 66, 36, 0);
        }
    }

    @keyframes pulse-bar-chart {
        0% {
            box-shadow: 0 0 0 0 rgba(89, 157, 179, 0.6);
        }

        70% {
            box-shadow: 0 0 0 15px rgba(89, 157, 179, 0);
        }

        100% {
            box-shadow: 0 0 0 20px rgba(89, 157, 179, 0);
        }
    }

    @keyframes pulse-book {
        0% {
            box-shadow: 0 0 0 0 rgba(172, 166, 160, .6);
        }

        70% {
            box-shadow: 0 0 0 15px rgba(172, 166, 160, 0);
        }

        100% {
            box-shadow: 0 0 0 20px rgba(172, 166, 160, 0);
        }
    }

    &:hover {
        color: #fff;

        span, span.bar-chart {
            animation: pulse-bar-chart 2s infinite;
        }

        span.heartbeat {
            animation: pulse-heart 2s infinite;
        }

        span.book {
            animation: pulse-book 2s infinite;
        }
    }

    &.no-icon {
        padding-left: 22px;
    }


    span {
        width: 45px;
        height: 45px;
        display: inline-block;
        border-radius: 50%;
        background-color: $lighter-blue;
        position: absolute;
        left: -1px;
        top: 0;

        .fas {
            @include centerer;
            font-size: 19px;
        }

        &.heartbeat {
            background: $comp-red;
        }

        &.book {
            background: $medium-gray;
        }
    }
}

