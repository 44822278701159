/// <reference path="../../app.scss" />

//Mobile scroll FIX.
.mobile-scroll {
    overflow: hidden;

    @include breakpoint(large) {
        overflow: visible;
    }
}

.nav-wrapper {
    &.fixed {
        .global-navigation {
            width: 100%;
            position: fixed;
            top: 0;
            z-index: 99999;
            box-shadow: 1px 1px 2px rgba(0,0,0,.65);
        }
    }
}

.global-navigation {
    -webkit-tap-highlight-color: rgba(0,0,0,0); //Fix mobile menu toggle flicker
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;
    transform: translate3d(0px,0px,0px);

    .website-logo {

        img {
            width: 100%;
            max-width: 150px;
            margin-left: .9375rem;
            top: 50%;
            position: absolute;
            transform: translateY(-50%);

            @include breakpoint(large) {
                max-width: 400px;
            }
        }
    }

    @include breakpoint(large) {
        position: relative;
    }

    &.fixed {
        position: fixed;
        top: 0;
        z-index: 99999;
    }

    &.search-active {
        overflow: visible !important;
    }

    &.open {
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 100%;

        .title-bar {
            box-shadow: none;
        }
    }

    @include breakpoint(large) {
        overflow: hidden;
        position: relative;
        overflow: hidden !important;
    }
}

/// Mobile top bar menu style
/// @group comp
.title-bar {
    background: $primary-color;
    padding: 0;
    height: 60px;
    width: 100%;
    z-index: 100;
    border: none;
    position: relative;
    box-shadow: 1px 1px 2px rgba(0,0,0,.65);

    .title-bar-right {
        height: 100%;
        font-size: 0;

        .mobile-menu, .mobile-search {
            display: inline-block;
            text-align: center;
            cursor: pointer;
            border-left: 1px solid $medium-blue;
            height: 100%;

            &.active {
                background: $sky-blue;

                button {

                    .fas {
                        color: $primary-color;
                    }

                    .title-bar-title {
                        color: $primary-color;
                    }
                }
            }

            button {
                padding: 10px 15px;
                cursor: pointer;
                width: 62px;

                .fas {
                    color: $white;
                    font-size: 19px;
                }
            }

            .title-bar-title {
                display: block;
                font-size: 12px;
                color: $white;
                margin-top: 8px;
            }
        }
    }
}

/// Desktop/Larger screen top bar menu style
/// @group comp
.top-bar {
    background: $sky-blue;
    position: fixed;
    height: 100%;
    width: 100%;
    top: auto;
    position: relative;
    overflow: hidden;
    z-index: 99;
    align-items: flex-start;
    padding: 0;
    display: none;

    @include breakpoint(large) {
        background: $primary-color;
        //padding: .5rem .5rem 0 .5rem;
        box-shadow: 0 3px 1px 0 rgba(0,0,0,.4);
        display: block !important;
    }

    .home-link {
        &.tabbed {
            svg {
                @include tabbedStyle();
                outline-offset: 10px;
            }
        }
    }

    .tabbed {
        outline-color: $white;
    }

    .top-bar-left {
        width: 100%;

        @include breakpoint(large) {
            float: none;
        }

        > ul.menu {
            text-align: left;

            @include breakpoint(large) {
                text-align: center;
                height: 55px;
            }

            ul.submenu {
                display: block;
                background: $white;

                @include breakpoint(large) {
                    display: none;
                }
            }
        }

        .usability-menu {
            display: block;
            margin-top: $global-margin*2;
            border-top: 1px solid darken($sky-blue, 8%);

            @include breakpoint(large) {
                display: none;
            }

            li {
                a {
                    padding-right: 18px;
                    color: $primary-color;

                    @include breakpoint(large) {
                        color: #FFF;
                    }
                }
            }

            .menu {
                li {
                    a {
                        svg {
                            right: 16px;
                            position: absolute;
                        }
                    }
                }
            }
        }
    }

    ul.menu {
        //First level navigation
        &.level-0 {
            li {
                > a {
                    text-transform: uppercase;
                    font-size: 12px;

                    svg {
                        width: 20px;
                        height: 20px;

                        @include breakpoint(large) {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 5px;
                            margin-top: -5px;
                        }
                    }

                    @include breakpoint(large) {
                        font-size: 13px;
                        padding: 0 .8rem;
                    }
                }
            }
        }

        @include breakpoint(large) {
            background: transparent;
        }


        li {
            position: relative;

            &.has-children.active {
                background: $white;

                @include breakpoint(large) {
                    background: transparent;
                }
            }

            &.active {

                @include breakpoint(large) {
                    &:after {
                        position: absolute;
                        background: $comp-green;
                        height: 4px;
                        bottom: 0;
                        content: "";
                        width: 100%;
                        left: 0;
                    }
                }

                a {
                    @include breakpoint(large) {
                        color: $white;
                    }

                    background: transparent;
                }

                > a {
                    color: #333;
                    font-weight: bold;

                    @include breakpoint(large) {
                        color: $white;
                        font-weight: normal;
                    }
                }

                &.current {
                    > a {
                        &:after {
                            background: $comp-green;
                            width: 4px;
                        }
                    }
                }
            }

            .toggle-container {
                position: absolute;
                top: 0;
                right: 0px;
                height: 39px;
                width: 62px;
                cursor: pointer;
                display: block;

                @include breakpoint(large) {
                    display: none;
                }

                .toggle-subs {
                    transition: all .3s;
                    width: 100%;
                    height: 100%;

                    &:before, &:after {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        margin: auto;
                        height: 3px;
                        background-color: $light-blue;
                        width: 14px;
                        pointer-events: none;
                        transition: all .1s;
                    }

                    &:after {
                        transform: rotate(90deg);
                    }
                }

                &.active {
                    .toggle-subs {
                        transform: rotate(90deg);

                        &:before {
                            opacity: 0;
                        }
                    }
                }
            }

            a {
                color: $primary-color;
                padding-right: 80px;
                border-bottom: 1px solid darken($sky-blue, 8%);

                &:after {
                    content: "";
                    background: darken($sky-blue, 8%);
                    width: 1px;
                    height: 100%;
                    right: 62px;
                    position: absolute;
                    top: 0;

                    @include breakpoint(large) {
                        content: none;
                    }
                }

                @include breakpoint(large) {
                    color: $white;
                    border-bottom: none;
                    padding: 0 .8rem;
                    line-height: 55px;
                }
            }

            .submenu {

                li {
                    height: 0;
                    overflow: hidden;
                    border: none;
                    transition: height .3s;

                    &.open {
                        height: auto;
                        transition: height 1.5s;
                    }

                    a {
                        text-transform: none;
                        line-height: 1.4;

                        &:last-child {
                            border-bottom: 1px solid darken($sky-blue, 8%);
                        }
                    }
                }

                &.level-1 {
                    li {
                        a {
                            padding-left: 2rem;
                        }
                    }
                }

                &.level-2 {
                    li {
                        a {
                            padding-left: 3rem;
                        }
                    }
                }
            }
        }
    }
}
