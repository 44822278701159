/// <reference path="../../app.scss" />

.list-box {
    margin-bottom: $global-margin;

    &:last-child {
        margin-bottom: $global-margin*2;
    }

    .box-heading {
        .list-cat {
            float: left;

            .fas {
                margin-right: 5px;
                width: 0.875em;
                height: 0.875em;
            }
        }

        .list-date {
            float: left;
            width: 100%;
            font-weight: 400;
            font-size: $font-size-small;

            @include breakpoint(smaller) {
                float: right;
                width: auto;
            }
        }
    }

    .row {
        &.list-row {
            margin: 0;
        }
    }

    .box-content {
        img {
            margin-bottom: $global-margin;

            @include breakpoint(medium) {
                margin-bottom: 0;
            }
        }

        &.list-meta {
            background-color: $sky-blue-lighter;
        }

        p {
            display: inline;
        }

        h2 {
            font-weight: 700;
            font-style: italic;
            font-size: 1.3125rem;

            svg {
                margin-left: 4px;
            }
        }
    }
}
