/// <reference path="../app.scss" />

//Teaser-area
.teaser-area {
    @include breakpoint(medium) {
        background-color: $sky-blue;
    }
}


/// Artical-teaser
/// @group comp
.article-teaser {
    position: relative;
    margin-bottom: $global-margin;
    padding-right: .625rem;
    padding-left: .625rem;

    @include breakpoint(medium) {
        margin-bottom: 0;
    }
    // second artical
    &:nth-child(2n) {
        .article-teaser-content {
        }

        @include breakpoint(medium) {
            text-align: right;
        }

        .article-teaser-img {

            @include breakpoint(medium) {
                float: right;
            }

            &:after {
                right: auto;
                left: 0;
                border-width: 30em 0 0 9em;
                border-color: transparent transparent transparent $sky-blue;
            }
        }
    }
    // content img
    .article-teaser-img {
        width: 55%;
        background-position: 50% 50%;
        background-size: cover;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 0;
            height: $articaltsr-height;
            border-style: solid;
            border-width: 30em 9em 0px 0px;
            border-color: transparent $sky-blue transparent transparent;
        }
    }
    // content tx
    .article-teaser-content {
        width: 45%;
        z-index: 2;
        background-color: $sky-blue;


        @include breakpoint(medium down) {
            padding: $global-padding;
        }

        h2 {
            font-size: 24px;
            font-style: italic;
            font-weight: 400;
            margin-bottom: $global-margin;
            line-height: 1.3;

            &.truncate-teaser-heading {
                max-height: 65px;
                overflow: hidden;
            }

            svg {
                position: relative;
                top: 3px;
            }
        }

        p {
            font-size: 16px;

            &.truncate-teaser-text {
                max-height: 75px;
                overflow: hidden;
            }
        }
        //content-tx center
        .inner {


            @include breakpoint(medium only) {
                width: 80%;
            }

            @include breakpoint(medium) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 90%;
            }
        }
    }
    //action-btn
    .btn {
        margin-top: $global-margin;

        @include breakpoint(smaller down) {
            padding: $global-padding/2;
        }
    }
    // common-class
    .article-part {
        float: left;
        position: relative;
        overflow: hidden;
        min-height: 20em;
        height: $articaltsr-height;


        @include breakpoint(medium down) {
            width: 50%;
            height: auto;

            &:after {
                display: none;
            }
        }

        @include breakpoint(smaller down) {
            width: 100%;
        }
    }
}
