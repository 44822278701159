/// <reference path="../app.scss" />

.articlepage, .guidelinearticlepage, .newspage, .eventpage {
    .page-footer {
        margin-top: 0;
    }
}

/// Page footer
/// @group global
.page-footer {
    margin-top: $global-padding*3;
    padding: $global-padding*2 0;
    background-color: map-get($foundation-palette, primary);
    color: #FFF;
    font-weight: 300;
    font-size: 13px;
    text-align: center;

    @include breakpoint(medium) {
        text-align: left;
    }

    h2 {
        margin-bottom: $global-margin;
        font-size: 1.3125rem;
    }

    a {
        color: #FFF;
    }

    .contact-column {
        margin-bottom: $global-margin*3;

        @include breakpoint(medium) {
            text-align: left;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        column-count: 1;
        // margin-top: -$global-margin/2;
        margin-bottom: $global-margin*2;

        @include breakpoint(medium) {
            column-count: 2;
            column-gap: 30px;
        }

        li {
            border-top: 1px solid #0c5b75;
            position: relative;

            @include breakpoint(medium) {
                border-top: 1px solid #0c5b75;
                margin-right: .9375rem;
            }

            &:last-child {
                border-bottom: 1px solid #0c5b75;

                @include breakpoint(medium) {
                    border-bottom: none;
                }
            }

            a {
                position: relative;
                display: inline-block;
                padding: $global-margin/2 0;
                font-weight: 300;
                width: 100%;
                max-width: 100%;
                //padding-right: $global-padding;
            }

            svg {
                position: relative;
                top: 1px;
                margin-left: 10px;
            }
        }
    }

    .contact-column {
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .quick-btn-column {
        .columns {
            &:last-child {
                .btn-quick {
                    margin-bottom: 0;
                }
            }
        }

        .btn-quick {
            background-color: $offblack;
            margin-bottom: $global-margin;
            width: 95%;
            max-width: 300px;
            transition: background-color .2s;

            @include breakpoint(medium) {
                max-width: 350px;
                width: 100%;
            }

            @include breakpoint(large) {
                max-width: none;
            }

            &:hover {
                background-color: darken($offblack,2%);

                span {
                    animation: pulse-bar-chart 2s infinite !important;
                }
            }

            span {
                background-color: $dark-gray;
            }
        }
    }

    .socialmedia-row {
        border-top: 1px solid #0c5b75;
        padding: $global-padding*2 0 0;
        margin-top: $global-margin*2;
        text-align: center;

        @include breakpoint(medium) {
            text-align: right;
        }
    }
}
