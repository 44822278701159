/// <reference path="../app.scss" />

/// Autocomplete
/// @group comp
.ui-autocomplete {
    border: 1px solid #999;
    background: #FFF;
    overflow: auto;
    z-index: 99999;
}

.ui-helper-hidden-accessible {
    display: none;
}
/// Autocomplete category styling
/// @group comp
.ui-autocomplete-category {
    padding: 8px 8px 4px 15px;
    font-weight: bold;
    background: $sky-blue;
    color: $offblack;

    .show-all {
        background: $primary-color;
        text-align: center;
        transition: background .3s;
        color: $white;
        padding: 15px;
        width: 100%;
        cursor: pointer;

        &:hover {
            background: lighten($primary-color, 8%) !important;
        }

        button {
        }
    }
}

/// Autocomplete item styling
/// @group comp
.ui-autocomplete >li, .ui-menu-item {

    > div, a {
        padding: 8px 15px;
        cursor: pointer;
        position: relative;
        transition: background .3s;
        display: block;
        transition: none;

        &:hover {
            background: $sky-blue;
        }

        svg {
            margin-right: 10px;
            vertical-align: middle;
        }

        div {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: top;
            width: 80%;
        }

        .fa-angle-double-right {
            position: absolute;
            right: 10px;
            top: 13px;
        }

        &.ui-state-active {
            background: $sky-blue;
            outline-style: solid;
            outline-width: 3px;
            outline-offset: -3px;
        }
    }
}

.autocomplete-suggestions strong {
    font-weight: normal;
    color: $suggestions-blue;
}

.autocomplete-group strong {
    display: block;
    border-bottom: 1px solid #000;
}
