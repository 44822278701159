﻿/// <reference path="../app.scss" />

.slideUp {
   
}

@keyframes slideUp {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0%);
    }
}

.cookies {
    padding: 20px;
    background-color: $light-blue;
    color: $white;
    font-size: 12px;
    z-index: 4;
    position: fixed;
    bottom: 0;
    transform: translateY(100%);
    width: 100%;
    animation-name: slideUp;
    animation-delay: 1s;
    animation-duration: .8s;
    animation-fill-mode: forwards;

    @include breakpoint(large) {
        font-size:13px;
    }

    .large-centered {
        animation-name: slideUp;
        animation-delay: 1s;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    p {
        margin: 0;
    }

    a {
        color: $white;
        text-decoration: underline;
    }

    #ButtonCookie {
        color: $white;
        background-color: darken($light-blue, 18%);
        padding: 10px 25px;
        cursor: pointer;
        border-radius: 20px;
        margin-top: 15px;

        @include breakpoint(large) {
            margin: 0;
        }
    }
}
