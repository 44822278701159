/// <reference path="../../app.scss" />

.qa-form-box {
    .EPiServerForms {
        box-shadow: none;
        background-color: transparent;
        margin-bottom:0;

        .Form__Description {
            padding-top: 40px;
            margin-top: -30px;
        }

        .Form__Title {
            padding: $global-padding;
            font-size: 30px;
            font-style: italic;
            margin: 0;
        }
    }
}

