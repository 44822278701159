/// <reference path="../../app.scss" />

.list-footer {
    transition: height .3s;
    @extend .article-footer;

    #list-share {
        button {
            cursor: pointer;
        }
    }

    .info-window a {
        font-weight: 700;
    }

    a {
        &:hover {
            text-decoration: underline;
        }
    }

    #feed {
        display: none;
        transition-duration: .3s;
        float: left;
        margin-top: $global-margin;

        #feed-link {
            font-size: $font-size-small;
        }

        .button {
            cursor: pointer;
            font-size: $font-size-small;

            svg {
                margin-right: 6px;
            }
        }
    }

    @keyframes fade-in {
        0% {
            opacity: 0;
            bottom: -180px;
        }

        100% {
            opacity: 1;
            bottom: -160px;
        }
    }

    @include info-window($white, $sky-blue);

    .info-window {
        position: absolute;
        max-width: 325px;
        opacity: 0;
        bottom: -180px;


        &.active {
            animation: fade-in .9s forwards;
            visibility: visible;
            bottom: -160px;
            opacity: 1;
        }



        p {
            margin: 0;
        }
    }
}
