/// <reference path="../app.scss" />

.modal-logout {
    text-align: center;

    .button {
        margin: $global-padding/2;
    }
}

/// Global menu with login and language change
/// @group global
.usability-menu {
    display: block;
    transition: all .4s;
    margin: 0;

    @include breakpoint(large) {
        display: inline-block;
        border-bottom: 1px solid #376a7b;
    }

    li {
        display: block;
        width: 100%;

        a, button {
            padding: .9rem 1rem;
            display: block;
            @include breakpoint( medium down) {
                &:after {
                    display: none;
                }
            }
        }

        &.select-language {
            .is-dropdown-submenu {
                left: 0 !important;
            }
        }

        @include breakpoint(large) {
            width: auto;
            border-bottom: none;
            display: inline-block;
        }

        @include breakpoint(large) {
            &:first-child {
                a {
                    padding: $global-padding/2 $global-padding $global-padding/2 0;
                }
            }

            &:last-child {

                a {
                    padding: $global-padding/2 0 $global-padding/2 $global-padding;
                }
            }
        }

        &.is-dropdown-submenu-parent {

            > a {
                &:after {
                    transition: all .2s;
                }
            }

            &.is-active {
                > a {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .is-dropdown-submenu {
            z-index: 200;
            min-width: 300px;
            text-align: left;
            box-shadow: 0 3px 1px 0 rgba(0,0,0,.1);
            left: $global-padding !important;

            li {
                border-bottom: 1px solid $sky-blue;

                &.logout-item {
                    background: $sky-blue;
                }

                &:hover {
                    background: $sky-blue-lighter;
                }

                a {
                    padding: $global-padding/2;

                    svg {
                        position: absolute;
                        right: 5px;
                    }
                }
            }
        }
    }

    > li {

        > a {
            &:after {
                @include breakpoint( medium down) {
                    top: 22px !important;
                    right: 26px !important;
                }
            }
        }

        .is-dropdown-submenu {
            &.js-dropdown-active {
                li {
                    @include breakpoint( medium down) {
                        display: block;
                    }
                }
            }

            @include breakpoint( medium down) {
                border: none;
                box-shadow: none;
                position: static;
                top: auto;
                right: auto;
                left: auto;
                bottom: auto;
            }

            li {
                @include breakpoint( medium down) {
                    height: auto !important;
                    display: none;

                    a {
                        padding-left: 2rem;
                    }
                }
            }
        }
    }

    a, button {
        text-transform: uppercase;
        padding: .9rem 1rem;
        font-size: 12px;
        color: #00445b;
        font-weight: 600;

        svg {
            width: 12px;
            height: 12px;
            margin-right: 6px;
            color: $primary-color;
            transition: color .3s;
        }

        &:hover {

            svg {
                color: $medium-green;
            }
        }
    }


    .submenu {
        li {
            a {
                svg {
                    float: right;
                }
            }
        }
    }
}

/// Global header
/// @group global
header {
    position: relative;

    @include breakpoint(large) {
        height: auto;
    }

    .top-navigation {
        display: none;

        @include breakpoint(large) {
            padding: 15px 0;
            display: block;
        }

        .website-logo {

            img {

                @include breakpoint(large) {
                    max-width: 400px;
                }
            }
        }

        .header-navigation {
            padding-top: 25px;

            @include breakpoint(medium only) {
                padding-top: 0;
            }
        }

        nav {
            display: block;
            transition: all .4s;
            overflow: hidden;

            @include breakpoint(large) {
                display: inline-block;
                margin-right: 25px;
            }
        }
    }
}
