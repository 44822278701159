/// <reference path="../app.scss" />


// BOX For epiforms
.EPiServerForms {
    box-shadow: 0 3px 1px 0px rgba($black, .1);
    background-color: $white;
    margin-bottom: $global-margin*2;
    // First element of box
    > .Form__Element {
        margin-bottom: 0;
    }
    // BOX For all elements
    .Form__Element {
        position: relative;
        margin-top: $global-margin;
        display: inline-block;
        overflow: visible;
        width: 100%;
        // Caption
        .Form__Element__Caption {
            padding-left: 10px;
            font-size: 14px;
            color: darken($medium-gray, 25%);
            cursor: text;
            display: block;
            text-transform: uppercase;
            line-height: 1.98;
        }
        // Textbox, transitions
        .FormTextbox {
            label.Form__Element__Caption {
                position: absolute;
                top: 2px;
                transition: all .2s;
                transform: rotate(0) translate3d(0,0,0);
                backface-visibility: hidden;
                transform-origin: 50% 50%;

                &.focus {
                    font-size: 12px;
                    transform: translate3d(0, -24px,0);
                }
            }
        }
        // Range
        .FormRange {
            span {
                .FormRange__Input {
                    float: left;
                }

                > span {
                    float: left;
                    padding: 5px 10px;
                }
            }
        }
        // Checkboxes / radiobuttons
        &.FormChoice {
            &.FormChoice--Image {
                .FormChoice--Image__Item {
                    text-align: left;
                    display: block;
                    max-width: none;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: $small-font-size;

                    .FormChoice__Input,
                    .FormChoice--Image__Item__Caption {
                        display: inline;
                    }

                    img {
                        display: block;
                        width: 120px;
                    }
                }
            }
        }
        // Upload files
        .FormFileUpload__Input {
            font-size: 12px;
        }
        // Captcha
        .FormCaptcha .FormCaptcha__Refresh {
            cursor: pointer;
            color: $primary-color;
        }
        // Submits form
        .FormSubmitButton {
            width: 100%;
            cursor: pointer;
            @extend .btn, .btn-white;
            margin: 0;

            &:hover {
                color: $black;
            }
        }
        // Reset all form
        &.FormResetButton {
            float: right;
            cursor: pointer;
            border-width: 0 0 0 0;
            padding: 5px;
            width: auto;
            height: auto;
            line-height: 1;
            margin-top: 15px;

            &:hover {
                border-bottom: 1px solid $beige-gray;
                margin-bottom: -1px;
            }
        }
        // Validation for any req fields (star)
        &.ValidationRequired {
            svg {
                top: 8px;
                color: $comp-red;
                position: absolute;
                right: 5px;
            }
        }
        // Validation for any req fields (message)
        .Form__Element__ValidationError {
            position: absolute;
            bottom: -20px;
            right: 0;
            font-size: 10px;
            transition: all .2s;
            color: $comp-red;
        }
        // Default styles
        input, textarea {
            background-color: transparent;
            border-color: $beige-gray;
            border-style: solid;
            border-width: 0 0 1px 0;
            box-shadow: none;
            padding: 5px 10px;
            height: auto;
            font-size: 14px;
            margin: 0;

            &:valid {
            }

            &:required {
            }

            &:focus {
                border-color: $dark-gray;
            }

            &.FormChoice__Input--Checkbox {
                margin-right: 3px;
            }


            &::placeholder {
                opacity: 0;
                transition: opacity .5s;
                color: $light-gray;
            }

            &:focus {
                &::placeholder {
                    opacity: 1;
                }
            }
        }

        textarea {
            border-width: 1px;
            resize: vertical;
            height: 120px;
            padding: 10px;

            &:focus {
                border-width: 1px;
            }
        }

        select {
            background-color: transparent;
            border-color: $beige-gray;
            border-style: solid;
            border-radius: $button-border-radius;
            box-shadow: none;
            padding: 5px 25px 5px 10px;
            height: auto;
            font-size: 12px;
            margin: 0;
            transition: all .2s;

            &:focus {
                outline: -webkit-focus-ring-color auto 5px;
            }
        }
        // Removes arrows in numberfields
        input[type=number] {
            -moz-appearance: textfield;
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
        }
    }
    // STEP FORM
    .Form__NavigationBar {
        .Form__NavigationBar__ProgressBar {
            background: $light-gray;
            border: none;
            margin: 0;
            padding: 0;

            .Form__NavigationBar__ProgressBar--Progress {
                background-color: $dark-gray;
                height: 0.15rem;
                position: relative;
                border: none;

                &:after {
                    content: " ";
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 20px;
                    height: 20px;
                    background: $dark-gray;
                    color: $white;
                    text-align: center;
                    border-radius: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        button.Form__NavigationBar__Action {
            background: $dark-gray;
            border-radius: $button-border-radius;
            color: $offwhite;
            text-transform: uppercase;
            font-size: $font-size-extra-small;
            padding: $button-padding;
            height: auto;
            width: auto;
            border: none;

            svg {
                width: 1em;
                height: 1em;
                position: relative;
                top: 1px;
            }

            &.btnPrev {
                &:before {
                    padding-right: 5px;
                }
            }

            &.btnNext {
                svg {
                    padding-left: 5px;
                }
            }

            &:disabled {
                opacity: .6;
                cursor: default;
                color: $offwhite;
            }
        }
    }
    // MAIN BODY
    .Form__MainBody {
        padding: $global-padding/2 $global-padding;
        background: transparent;
    }
    // TITLE
    .Form__Title {
        text-transform: uppercase;
        background-color: $sky-blue;
        font-weight: 700;
        font-family: $body-font-family;
        position: relative;
        // force styling
        padding: $global-padding/2 $global-padding !important;
        font-size: 14px !important;
        margin-bottom: 0 !important;
        font-style: normal !important;
    }
    // DESCRIPTION
    .Form__Description {
        background-color: $offwhite;
        padding: $global-padding/2;
        text-align: center;
        margin-bottom: 0;
        margin-bottom: 1px;
        font-size: $font-size-small;
    }
    // STATUS MESSAGES
    .Form__Status__Message {
        background-color: $offwhite;
        text-align: center;
        font-size: 18px;

        > p {
        }

        &.Form__Success__Message {
            margin: 0;
            padding: $global-padding;
            background-color: $offwhite;
            color: $medium-green;

            p {
                margin: 0;
            }

            > p {
                font-size: 26px;
                font-weight: 300;
                text-align: center;
            }
        }

        &.Form__Warning__Message {
            margin: 0;
            background-color: $offwhite;
            color: $black;
            font-weight: 300;
        }
    }
}

