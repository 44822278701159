﻿/// <reference path="../app.scss" />

/// Hero area on startpage
/// @group comp
.hero {
    min-height: 280px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 40px 0;

    .hero-intro {
        max-width: 600px;
        margin: 0 auto 20px auto;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        h1 {
            font-size: 24px;
            font-weight: bold;
        }
    }

    .hero-links {
        > div {
            margin-bottom: 15px;

            .btn-quick {
                width: 90%;

                @include breakpoint(smaller) {
                    width: 70%;
                }

                @include breakpoint(medium) {
                    width: 50%;
                }

                @include breakpoint(large) {
                    width: 90%;
                }

                svg {
                    width:20px;
                    height: 20px;
                }
            }
        }
    }
}
