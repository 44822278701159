/// <reference path="../../app.scss" />

/// Breadcrumbs styling
/// @group comp
.breadcrumbs-nav {
    width: 100%;
    margin-bottom: $global-margin*2;
    margin-top: -$global-margin;

    .breadcrumbs {
        background: $sky-blue;
        width: 100%;
        margin: 0;
        padding: $global-padding/2;

        li {
            &.active {
                color: #6D6969;
            }
        }

        a {
            color: $offblack;
            font-weight: bold;
        }
    }
}

