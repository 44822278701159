/// <reference path="../app.scss" />

////
/// This is a poster comment.
/// It will apply annotations to all items from file.
/// @group util
/// @author Christoffer Persson & Pär Andersson
////

///Horizontally & Vertically align a element
///@author Christoffer Persson
@mixin centerer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

///Horizontally align a element
///@author Pär Andersson
@mixin centerer-horizontal {
    position: relative;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
}

/// Create custom circle with font-awesome icon
/// @param {background-color} $background - Background color for the circle
/// @param {color} fa-color - color for the font-awesome icon
/// @example
///     @include circle-fa(#000, #fff);
/// @author Christoffer Persson
@mixin circle-fa($background, $fa-color) {
    span {
        width: 45px;
        height: 45px;
        display: inline-block;
        border-radius: 50%;
        background-color: $background;
        position:relative;

        .fas {
            @include centerer;
            font-size: 19px;
            color: $fa-color;
        }
    }
}

/// Create Custom infowindow to show information to users in a clear way
/// @param {background-color} $background - Background color for the circle
/// @param {color} $border-color - border-color for the font-awesome icon
/// @example
///     @include circle-fa(#000, #fff);
/// @author Christoffer Persson
@mixin info-window($background, $border-color) {
    .info-window {
        position: relative;
        background: $background;
        border: 4px solid $border-color;
        padding: $global-padding;
        box-shadow: 0px 3px 4px 0 rgba(0,0,0,.3);
        visibility: hidden;
        z-index:999;

        .close-window {
            position: absolute;
            right: 5px;
            top: 5px;
            cursor: pointer;

            svg {
                font-size: 20px;
                color: $medium-gray;
            }
        }
    }

    .info-window:after, .info-window:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .info-window:after {
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: $background;
        border-width: 15px;
        margin-left: -15px;
    }

    .info-window:before {
        border-color: rgba(194, 225, 245, 0);
        border-bottom-color: $border-color;
        border-width: 21px;
        margin-left: -21px;
    }
}

/// Outputs for hidden objects in print
/// @author Katrin Risberg
@mixin hide-when-print {
    display: none !important;
    visibility: hidden !important;
    float: none !important;
    padding: 0 !important;
}
