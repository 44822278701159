/// <reference path="../../app.scss" />

.sub-nav {
    list-style-type: none;
    margin: 0;
    display: none;

    @include breakpoint(large) {
        display: block;
    }
    //First level navigation
    &.level-0 {
        padding-bottom: 80px;
        border-right: 1px solid darken($sky-blue, 8%);

        li {
            > a {
                text-transform: uppercase;
                font-size: 12px;
            }
        }
    }

    li {
        justify-content: flex-start !important;
        position: relative;

        &.has-children {

            &.active {

                &:after {
                    background: $comp-green;
                    width: 4px;
                    content: "";
                    height: 100%;
                    right: 0;
                    position: absolute;
                    top: 0;
                }
            }

            &.current {
                color: $dark-green;
                
            }
        }

        &.current {
            > a {
                color: $dark-green;
                font-weight: 700;
            }
        }

        .toggle-container {
            position: absolute;
            top: 0;
            right: 0px;
            height: 100%;
            width: 62px;
            cursor: pointer;
            display: block;

            &:before {
                content: "";
                background: darken($sky-blue, 8%);
                width: 1px;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
            }

            .toggle-subs {
                transition: all .3s;
                width: 100%;
                height: 100%;
                transform: rotate(0deg) translate3d( 0, 0, 0);
                backface-visibility: hidden;

                svg {
                    color: darken($gray, 10%);
                    pointer-events: none;
                    font-size: 24px;
                    transition: all .3s;
                    @include centerer;
                }
            }

            &.active {
                .toggle-subs {
                    transform: rotate(180deg) translate3d( 0, 0, 0);
                }
            }
        }

        a {
            color: $offblack;
            line-height: 1.6;
            text-decoration: none;
            display: block;
            padding: 1rem 5rem 1rem .2rem;
            text-transform: uppercase;
            border-bottom: 1px solid darken($sky-blue, 8%);
            transition: color .3s;

            &:hover {
                color: $dark-green;
            }
        }

        ul.level-1 {

            li {
                display:none;
                border: none;

                &.open {
                    display: block;
                }

                a {
                    line-height: 1.6;
                    padding-left: 1rem;
                    padding-right: 1rem;

                    &:last-child {
                        border-bottom: 1px solid darken($sky-blue, 8%);
                    }
                }
            }
        }
    }
}
