﻿/// <reference path="../app.scss" />

.page-section {
    margin-bottom:$global-margin*3;
}

.main-area {
    > h1 {
        @include breakpoint(medium) {
            padding-right: $global-padding*10;
        }
    }

    p {
        overflow: auto;
    }

    p.intro-p {

        @include breakpoint(large) {
            padding-right: 20%;
        }

        &.article-intro-p {
            padding-right: 0;

            @include breakpoint(large) {
            }
        }
    }

    img.article-img {
        width: 100%;
        margin-bottom: $global-margin;
    }
}

/// Applied to headings adds lines on the side like this: -----Heading-----
/// @group comp
.hr-heading {
    display: table;
    white-space: nowrap;
    font-style: italic;
    font-size: 32px;
    overflow: hidden;
    margin-bottom: $global-margin*2;

    &:before, &:after {
        border-top: 2px solid #e6ecef;
        content: '';
        display: table-cell;
        position: relative;
        top: .7em;
    }

    &:before {
        width: 10%;
        right: 1.5%;
    }

    &:after {
        width: 90%;
        left: 1.5%;
    }
}
