/// <reference path="../util/custom-mixins.scss" />
@import '../../util/custom-mixins';
@import '../../settings.scss';
@import '../../components/related-links.scss';

.box {
    &.more-docs {
        .box-content {
            border-top: 1px solid $sky-blue;

            h3 {
                font-weight: 700;
                margin: 0 0 $global-margin/3;
                font-size: .75rem;
                font-family: "Open Sans",Helvetica,Roboto,Arial,sans-serif;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    padding: 0;
                    margin: 0;
                }
            }

            .doc-col {
                svg {
                    width: 1em;
                    height: 1em;
                }

                a {
                    margin-bottom: $global-margin/3;
                    display: inline;
                    margin-left: 5px;
                }

                .doc-col-part {
                    margin-bottom: $global-margin;
                }

                .related-docs {

                    &.css-col {
                        @include breakpoint(medium) {
                            column-count: 2;
                        }
                    }
                }
            }
        }
    }
}
