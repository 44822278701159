/// <reference path="../app.scss" />


.startpage {

    .guidelines {
        margin: $global-margin*2 0 0 0;
        padding: 0 $global-padding;

        @include breakpoint(large) {
            margin: $global-margin*2 0;
        }

        .guidlines-intro {
            h2 {
                margin: 0 0 $global-margin 0;
                font-family: 'Open Sans';
                font-weight: bold;
                color: $dark-gray;
                font-size: $small-font-size;
                font-style: normal;
            }
        }
    }

    .list-box {
        background: transparent;
        box-shadow: none;
        border-left: 4px solid $comp-green;
        margin-bottom: $global-margin;

        @include breakpoint(large) {
            margin: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .box-heading {
            background: transparent !important;
            padding: 0 0 10px 10px;

            .list-cat {
                display: none;
            }

            .list-date {
                float: left;
                font-size: 11px;
            }
        }

        .box-content {
            padding: 0 0 0 10px;


            &.truncate-guidelines {
                max-height: 55px;
                overflow: hidden;

                @include breakpoint(large) {
                    max-height: 45px;
                }
            }

            > a {
                display: none;
            }

            h3 {
                font-size: 15px;
                display: inline;
                font-weight: 700;
                font-style: italic;

                &:after {
                    content: none;
                }
            }
        }
    }

    .list-area {
        .column:last-child:not(:first-child) {
            .list-block-heading {
                margin-top: $global-margin;

                @include breakpoint(medium) {
                    margin-top: 0;
                }
            }
        }
    }
}

/// Box component used in lists, e.g. on searchpage
/// @group comp

#filterBox {
    .box-heading {
        cursor: pointer;
    }
}

.box {
    box-shadow: 0 3px 1px 0px rgba($black, .1);
    background-color: $white;

    &.sky-blue-box {
        .box-content {
            background-color: $sky-blue;
        }
    }

    &.no-shadow {
        box-shadow: none;
    }

    &.no-bg {
        background-color: transparent;
    }

    &.list-filter-section {
        .fa-filter {
            margin-right: 5px;
        }
    }

    &.hidden-content {
        .box-heading {
            .toggle-box-content {

                .fa-angle-up {
                    transform: rotate(-180deg) translate3d(0,-2px,0);
                }

                .toggle-text-show {
                    @include breakpoint(medium) {
                        display: inline;
                    }
                }

                .toggle-text-hide {
                    @include breakpoint(medium) {
                        display: none;
                    }
                }

                &.active {

                    .fa-angle-up {
                        transform: rotate(0deg) translate3d(0,0,0);
                    }

                    .toggle-text-hide {
                        @include breakpoint(medium) {
                            display: inline;
                        }
                    }

                    .toggle-text-show {
                        @include breakpoint(medium) {
                            display: none;
                        }
                    }
                }
            }
        }

        .box-content, .box-footer, .search-tab-list {
            display: none;
        }
    }

    &:not(.contact-block) {
        .box-heading, .box-heading h2 {
            text-transform: uppercase;
            background-color: $sky-blue;
            font-size: 14px;
            font-weight: 700;
            font-family: $body-font-family;
            margin-bottom: 0;
        }
    }

    .box-heading {
        padding: $global-padding/2 $global-padding;
        position: relative;

        svg {
            width: 0.875em;
            height: 0.875em;
        }

        &.no-bg {
            background-color: transparent;
            background: none;
        }

        .toggle-box-content {
            background-color: $comp-green;
            border-radius: 15px;
            padding: 8px;
            width: 110px;
            border: 0;
            font-size: 11px;
            position: absolute;
            right: 1.5rem;
            top: 0;
            bottom: 0;
            height: 27px;
            margin: auto;
            cursor: pointer;

            @include breakpoint(smaller down) {
                width: auto;
                background-color: transparent;
            }

            &.active {
                .fa-angle-up {
                    transform: rotate(-180deg) translate3d(0,-2px,0);
                }

                .toggle-text-hide {
                    @include breakpoint(medium) {
                        display: none;
                    }
                }

                .toggle-text-show {
                    @include breakpoint(medium) {
                        display: inline;
                    }
                }
            }

            &.large {
                position: relative;
                width: 100%;
                background-color: $comp-green;
                font-size: 18px;
                text-transform: uppercase;
                text-align: center;
                position: relative;
                font-weight: 300;
                border-radius: 30px;
                height: 60px;
                cursor: pointer;
                left: 0;
                padding-left: 60px;

                .fa-angle-up {
                    position: relative;
                    font-size: 20px;
                    margin-left: 6px;
                    right: auto;
                    left: auto;
                    height: 14px;
                    top: 0px;
                }

                &.active {
                    .fa-angle-up {
                        //transform: rotate(-180deg) translate3d(0,0px,0);
                    }
                }

                .large-toggle-icon {
                    height: 60px;
                    width: 60px;
                    line-height: 60px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: auto;
                    background-color: $offblack;
                    color: $white;
                    border-radius: 50%;

                    svg {
                        width: 1em;
                        height: 1em;
                        vertical-align: middle;
                    }
                }
            }

            &.wide-bar {
                height: auto;
                line-height: 1.5;
                border-radius: 0;
                background-color: $white;
                border-top: 1px solid $sky-blue;
                color: $black;
                font-weight: 400;
                font-size: 14px;
                padding: 10px 0;

                .fas {
                    height: 16px;
                }

                .toggle-icon {
                    color: $light-green;
                    font-size: 20px;
                    top: 2px;
                    position: relative;
                    margin-right: 3px;
                }

                .mobile-more-docs-icon {

                    .fas {
                        font-size: 16px;
                        display: inline-block;
                        height: 16px;
                        top: 1px;
                        position: relative;

                        @include breakpoint(medium) {
                            display: none;
                        }
                    }
                }
            }

            .toggle-text-show {
                display: none;

                &.show-always {
                    display: block !important;
                }
            }

            .toggle-text-show, .toggle-text-hide {
                @include breakpoint(smaller down) {
                    font-size: 12px;
                    line-height: 22px;
                    display: none;
                }
            }

            .fa-angle-up {
                margin-left: 15px;
                position: absolute;
                right: 15px;
                height: 14px;
                top: 0px;
                bottom: 0;
                margin: auto;
                font-size: 14px;
                transition: transform .3s;
                transform: rotate(0) translate3d(0,0,0);
                backface-visibility: hidden;
                transform-origin: 50% 50%;

                @include breakpoint(smaller down) {
                    font-size: 24px;
                    height: 24px;
                    right: 0;
                }
            }
        }
    }

    .box-content {
        padding: $global-padding;

        .detail-row {
            margin-bottom: $global-margin*1.3;

            &.doc-content-row {
                h3 {
                    font-weight: 700;
                    margin-bottom: $global-margin/3;
                }
            }

            &.date-span {
                span {
                    display: inline-block;
                    width: 35px;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            .detail-catagory {
                font-weight: 600;

                span {
                    font-weight: 400;
                }

                .fas {
                    margin-right: 2px;
                    font-size: 13px;
                    position: relative;
                    top: -1px;
                }

                h5 {
                    margin: 0;
                }
            }
        }

        .detail {
            text-transform: uppercase;
            font-size: $font-size-small;
            font-family: $body-font-family;
            color: $dark-gray;
            margin-bottom: 1px;
        }

        a {
            border-bottom: 0;
        }

        .box-content-section {
            border-color: $sky-blue;
            border-style: solid;
            border-width: 0 0 1px 0;
            padding-bottom: $global-padding;
            margin-bottom: $global-margin;

            &:last-child {
                border-width: 0;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }

    .box-footer {
        padding: $global-padding $global-padding $global-padding $global-padding;
        border-top: 1px solid $beige-gray;
    }

    &.publications-box {
        .box-content {

            h2 {
                font-size: $global-font-size;
            }
            .detail-row {
                margin-bottom: $global-margin;

                &:last-child {
                    margin-bottom: 0;
                }

                .detail-title {
                    font-family: $heading-font-family;
                    font-style: italic;
                    font-weight: $global-weight-bold;
                    font-size: 1rem;
                    line-height: 1.4;
                    margin-top: -1px; // align with desc/name
                }

                .detail-name {
                    text-transform: uppercase;
                    font-size: 0.8rem;
                    font-weight: 400;
                    line-height: 1.8;
                }
            }
        }

        .box-footer {
            border-color: $sky-blue;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;

            a {
                strong {
                    vertical-align: middle;
                }

                svg {
                    margin-left: 5px;
                }
            }
        }
    }
}

/// Contact block for contact information
/// @group comp
.contact-block {
    @extend .box;
    margin-bottom: $global-margin*1.5;



    .box-heading {
        background-color: $light-gray;
        padding: $global-padding/2 $global-padding;
        margin-bottom: 0;
        position: relative;

        &.with-image {
            padding-bottom: 45px;

            @include breakpoint(smaller only) {
                padding-bottom: .75rem;
            }

            h2 {
                margin-bottom: .5rem;

                @include breakpoint(smaller only) {
                    margin-bottom: 0;
                }
            }
        }

        @include breakpoint(smaller only) {
            padding-bottom: .75rem;
        }

        h2 {
            text-transform: uppercase;
            font-family: $body-font-family;
            font-weight: 700;
            text-align: center;
            font-size: $font-size-small;
            margin-bottom: 0;
            /*@include breakpoint(smaller only) {
                text-align: left;
                margin-bottom: 0;
            }*/
        }

        .contact-image {
            border: 4px solid #aca6a0;
            box-shadow: 0 0 0 2px #eee;
            width: 90px;
            height: 90px;
            border-radius: 50%;
            margin: 0 auto;
            overflow: hidden;
            position: absolute;
            @include horizontal-center;

            @include breakpoint(smaller only) {
                top: $global-padding/1.5;
                right: $global-padding/1.5;
                left: auto;
                transform: none;
            }

            img {
                position: absolute;
                @include centerer;
            }
        }
    }

    .box-content {

        &.heading-has-image {
            padding-top: 45px;
        }

        @include breakpoint(smaller only) {
            padding-top: $global-padding;
        }

        .detail-name {
            text-align: left;

            @include breakpoint(smaller only) {
                text-align: right;
            }
        }

        h3 {
            display: inline-block;
        }
    }

    &.middle {

        .box-heading {
            @include breakpoint(smaller) {
                padding-bottom: .75rem;
            }

            h2 {
                @include breakpoint(smaller) {
                    text-align: left;
                    margin-bottom: 0;
                }
            }

            .contact-image {
                @include breakpoint(smaller) {
                    top: $global-padding/1.5;
                    right: $global-padding/1.5;
                    left: auto;
                    transform: none;
                }
            }
        }

        .box-content {
            @include breakpoint(smaller only) {
                padding-top: $global-padding;
            }

            .detail-name {

                @include breakpoint(medium) {
                    text-align: right;
                }
            }

            &.heading-has-image {

                @include breakpoint(medium) {
                    padding-top: $global-padding;
                }


                .cat-name {

                    @include breakpoint(medium) {
                        max-width: 250px;
                        float: left;
                    }
                }
            }
        }
    }
}
