/// <reference path="../../app.scss" />

.search-filter {
    margin-bottom: 0;

    .search-page-search {
        position: relative;
        width: 100%;
        padding: 10px 0;
        z-index: 98;

        ul {
            li {
                width: 100%;

                &:first-child {
                    position: relative;
                }
            }
        }

        svg {
            right: 50px;
        }

        input {
            border-color: $light-gray;
            border-style: solid;
            border-width: 0 0 1px;
            box-shadow: none;
            padding-right: 25px;
            width: calc(100% - 60px);
        }

        .mobile-search-button {
            background: $dark-gray;
            border-radius: 100%;
            color: $white;
            width: 40px;
            height: 40px;
            position: absolute;
            cursor: pointer;
            right: 0;
            top: 0;
        }
    }
}
