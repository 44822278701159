﻿/// <reference path="../util/custom-mixins.scss" />
@import '../util/custom-mixins';
@import '../settings.scss';
@import '../components/box.scss';

//Teaser-area
/// Teaser-area chart styling
/// @group comp
.charts {
    margin-bottom: $global-margin*1.5;

    .iframe {
        width: 100%;

        iframe {
            width: 100%;
            border: none;
            height:410px;
        }
    }
}

