﻿/// <reference path="../app.scss" />

.right-area {

    .related-links {
        &:last-child {
            margin-top: $global-margin/1.5;
        }
    }

    .btn-quick {
        margin-bottom: $global-margin;      

        @include breakpoint(medium) {
            width: 100%;
        }
    }
}
