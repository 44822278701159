﻿/// <reference path="../app.scss" />

@import 'pikaday';

input::-webkit-calendar-picker-indicator {
    //display: none;
}
.pika-single {
    //display:block!important;

    .pika-lendar {
        font-family: $body-font-family;

        .pika-label {
            text-transform: uppercase;
            font-size: 12px;
        }
    }
}

table.pika-table {

    tbody,
    tbody tr,
    tbody tr td,
    tbody tr th,
    thead,
    thead tr,
    thead tr td,
    thead tr th,
    tfoot,
    tfoot tr,
    tfoot tr th,
    tfoot tr td {
        background-color: $white;
        border: 0;
        padding: 1%;

        .pika-button {
            border-radius: 50%;
            background: $white;
            color: $body-text-color;
            box-shadow: none;
            text-align: center;
            line-height: $global-lineheight*1.2;
            transition: $button-transition;


            &:hover {
                color: $white;
                background: $lighter-blue;
            }
        }

        &.is-selected .pika-button {
            background: $primary-color;
            color: $offwhite;
        }
    }
}
