/// <reference path="../app.scss" />


.article-footer {
    @extend .box;
    padding: 0;
    font-size: 12px;
    margin-bottom: $global-margin*2;

    @include breakpoint(medium) {
        margin-bottom: $global-margin*3;
    }

    a {
        border: 0;

        &:hover {
            border: 0;
        }
    }

    h2 {
        margin: 0;
        padding: 0;
        font-family: $body-font-family;
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: $global-margin/2;

        @include breakpoint(medium) {
            display: inline-block;
            vertical-align: middle;
            margin: 0 $global-margin 0 0;
        }
    }

    .footer-left {
        text-align: center;

        @include breakpoint(medium) {
            text-align: right;
            position: relative;

            h2 {
                margin-right: 0;
            }
        }
    }

    .footer-right {
        font-weight: 700;
        text-align: center;

        @include breakpoint(medium) {
            text-align: left;
            padding-left: 6%;
        }

        @include breakpoint(xlarge) {
            text-align: left;
            padding-left: 15%;
        }

        &.footer-meta {
        }
    }

    .socialmedia {
        padding: $global-padding/2;
        margin: 0;
        background-color: $comp-green;
        line-height: 0;
        text-align: center;
        position: relative;

        @include breakpoint(medium) {
            text-align: left;
        }

        .rss {
            margin-bottom: $global-margin*1.5;
            position: relative;

            @include breakpoint(medium) {
                margin-bottom: 0;
            }

            .share-part {
                display: inline-block;
                vertical-align: middle;

                .socialmedia-btn {

                    svg {
                        color: $white;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    button {
                        cursor: pointer;
                    }
                }
            }
        }

        .share {

            @extend .rss;
            margin-bottom: 0;

            @include breakpoint(medium) {
                text-align: right;
            }
        }

        .footer-left {
            h2 {

                @include breakpoint(medium) {
                    @include vertical-center;
                    right: 0;
                    margin-right: .9375rem;
                }
            }
        }

        .footer-right {
            text-align: center;

            @include breakpoint(medium) {
                text-align: left;
            }
        }
    }

    .article-meta-data {
        padding: $global-padding;

        .meta-row {
            padding: $global-padding/2 0;

            &.article-print-row {
                padding-bottom: 0;
                text-transform: uppercase;

                .footer-right {
                    text-align: center;

                    @include breakpoint(medium) {
                        text-align: right;
                    }
                }

                .fas {
                    margin-right: 5px;
                }
            }
        }
    }
}
