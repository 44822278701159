/// <reference path="../app.scss" />

/// Teaser block
/// @group comp

.teaser {
    @extend .box;
    padding: 0;
    position: relative;
    margin-bottom: $global-margin*1.5;
    background-color: $white;
    z-index: 2;

    h3 span {
        a {
            color: inherit;
        }
    }

    &.video-block {
        &:hover {
            h2 {
                opacity: 0;
                visibility: hidden;
            }
        }

        h2 {
            transition: opacity .3s, visibility .3s;
        }
    }

    &.no-image {
        height: auto !important;
    }

    &.patient {
        .teaser-image-wrap {
            h2 {
                > span {
                    background-color: $comp-red;

                    @include breakpoint(xlarge) {
                        background-color: rgba($comp-red, .8);
                        box-shadow: $global-margin 0 0 rgba($comp-red, .8),-$global-margin 0 0 rgba($comp-red, .8);
                    }
                }
            }
        }

        &:hover {
            h2 {
                > span {
                    @include breakpoint(xlarge) {
                        background-color: rgba(darken($comp-red, 5%), .8);
                        box-shadow: $global-margin 0 0 rgba(darken($comp-red, 5%), .8),-$global-margin 0 0 rgba(darken($comp-red, 5%), .8);
                    }
                }
            }
        }
    }

    &.data {
        .teaser-image-wrap {
            h2 {
                > span {
                    background-color: $light-blue;

                    @include breakpoint(xlarge) {
                        background-color: rgba($light-blue, .8);
                        box-shadow: $global-margin 0 0 rgba($light-blue, .8),-$global-margin 0 0 rgba($light-blue, .8);
                    }
                }
            }
        }

        &:hover {
            h2 {
                > span {
                    @include breakpoint(xlarge) {
                        background-color: rgba(darken($light-blue, 5%), .8);
                        box-shadow: $global-margin 0 0 rgba(darken($light-blue, 5%), .8),-$global-margin 0 0 rgba(darken($light-blue, 5%), .8);
                    }
                }
            }
        }
    }

    &.science {
        .teaser-image-wrap {
            h2 {
                > span {
                    color: $black;
                    background-color: $beige-gray;

                    @include breakpoint(xlarge) {
                        background-color: rgba($beige-gray, .8);
                        box-shadow: $global-margin 0 0 rgba($beige-gray, .8),-$global-margin 0 0 rgba($beige-gray, .8);
                    }
                }
            }
        }

        &:hover {
            h2 {
                > span {
                    @include breakpoint(xlarge) {
                        background-color: rgba(darken($beige-gray, 5%), .8);
                        box-shadow: $global-margin 0 0 rgba(darken($beige-gray, 5%), .8),-$global-margin 0 0 rgba(darken($beige-gray, 5%), .8);
                    }
                }
            }
        }
    }

    .teaser-image-wrap {
        position: relative;

        img {
            width: 100%;
        }

        h2 {
            position: relative;
            font-size: 16px;
            color: $white;
            margin-bottom: 0;

            @include breakpoint(xlarge) {
                max-width: 95%;
                padding: 0;
                position: absolute;
                bottom: -1.5rem;
                line-height: 2.33;
                padding: $global-padding/2;
                padding-left: $global-margin;
            }

            > span {
                position: relative;
                padding: $global-padding/1.5;
                z-index: 1;
                background-color: rgba($primary-color, .8);
                font-size: $global-font-size;
                box-shadow: none;
                display: block;
                box-decoration-break: clone;
                transition: all .2s;

                @include breakpoint(xlarge) {
                    display: inline;
                    color: $white;
                    box-shadow: $global-margin 0 0 rgba($primary-color, .8), -$global-margin 0 0 rgba($primary-color, .8);
                    padding: 7px 0;
                }
            }

            .fas {
                margin-right: $global-margin/2;
                transition: all .2s;
                vertical-align: baseline;
            }
        }
    }

    &:hover {
        box-shadow: 0 3px 3px 0px rgba($black, .2);

        h2 {
            > span {

                @include breakpoint(xlarge) {
                    background-color: rgba(darken($primary-color, 5%), .8);
                    box-shadow: $global-margin 0 0 rgba(darken($primary-color, 5%), .8),-$global-margin 0 0 rgba(darken($primary-color, 5%), .8);
                }
            }
        }
    }

    .teaser-content {
        position: relative;
        padding: $global-padding;

        @include breakpoint(xlarge) {
            padding: 29px $global-padding $global-padding $global-padding;
        }

        p {
            margin-bottom: 0;
        }
    }
}

.large-6 {
    .teaser {
        .teaser-image-wrap {
            h2 {
                @include breakpoint(xlarge) {
                    line-height: 40px;
                    padding-right: 5%;
                }

                @include breakpoint(large) {
                    font-size: 18px;
                }
            }
        }
    }
}

.large-3, .medium-3 {
    .teaser {
        .teaser-image-wrap {
            h2 {
                font-size: 14px;
                bottom: 0;
                position: relative;
                width: 100%;
                max-width: 100%;
                left: 0;
                margin-bottom: 0;
                padding: 0;
                line-height: $paragraph-lineheight;

                > span {
                    display: block;
                    padding: $global-padding/1.5;
                    box-shadow: none;
                }
            }
        }

        .teaser-content {
            padding: $global-padding;
            font-size: 13px;
        }
    }
}

.teaser.no-image {
    .teaser-image-wrap {
        h2 {
            bottom: 0;
            position: relative;
            line-height: $global-lineheight;
            padding: 0;
            left: 0;
            max-width: 100%;
            padding: 0;
            line-height: $global-lineheight;

            > span {
                box-shadow: none;
                display: block;
                padding: $global-padding/1.5;
            }
        }
    }

    .teaser-content {
        padding: $global-padding;
    }
}

.right-area {
    .teaser {
        @extend .teaser.no-image;
    }
}

.teasers {
    .row {
        .column {
            &:last-child {
                float: left;
            }
        }
    }
}
