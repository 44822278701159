﻿/// <reference path="../../app.scss" />


// Other solution

/// Pagination style
/// @group comp
ul.pagination {
    text-align: center;
    margin: 0;
    border-bottom: 1px solid $beige-gray;
    background-color: $dark-gray;

    .tabbed {
        outline-color: $white;
        outline-offset: -8px;
    }

    li {
        padding-top: 0;
        padding-bottom: 0;
        position: relative;
        
        // responsive break
        @include breakpoint(medium down) {
            $page-number-width: 90px;
            display: none;

            &.pagination-previous,
            &.pagination-next {
                float: none;
            }

            &.pagination-next {
                position: relative;
                //left: $page-number-width;
            }

            &:after {
                display: inline-block;
                content: attr(data-page) ' ' attr(data-of) ' ' attr(data-total);
                position: relative;
                text-align: center;
                color: $offwhite;
                text-transform: uppercase;
                font-size: $small-font-size;
                width: $page-number-width;
                right: $page-number-width;
            }
        }

        a {
            color: $offwhite;
            padding: $global-padding/1.5 $global-padding/1.5;

            @include breakpoint(large down) {
                padding: $global-padding/1.5 $global-padding/2;
            }


            &:hover {
                background-color: rgba($white, .1);
                color: $white;
            }
        }

        &.current {
            background: transparent;
            padding: inherit;

            a {
                &:after {
                    content: "";
                    height: 34px;
                    width: 34px;
                    position: absolute;
                    border: 2px solid $beige-gray;
                    border-radius: 50%;
                    bottom: 0;
                    right: 0;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
            }
        }

        &.ellipsis:after {
            color: $beige-gray;
            padding: 0;
        }

        &.pagination-previous,
        &.pagination-next {
            border: 1px solid $beige-gray;
            border-top: 0;
            border-bottom: 0;
            padding: 0;
            line-height: 1.8;

            &:before, &:after {
                display: none;
            }

            &.disabled {
                opacity: 1;

                a {
                    opacity: .4;
                    cursor: default;

                    &:hover {
                        background-color: transparent;
                    }
                }
            }

            a {
                text-transform: uppercase;
                font-size: $small-font-size;
                padding: $global-padding/1.5 $global-padding/2;

                &:before, &:after {
                    font-size: 18px;
                    line-height: 1;
                }
            }
        }

        &.pagination-previous {
            border-left: 0;
            float: left;
        }

        &.pagination-next {
            border-right: 0;
            float: right;
        }
    }
}