﻿/// <reference path="../../app.scss" />

.list-area {

    .columns {
        padding: 0 $global-padding;

        @include breakpoint(large) {
            padding: $global-column-padding;
        }

        &:first-of-type {
            margin-bottom: $global-margin*2;

            @include breakpoint(large) {
                padding-left: 5rem;
            }
        }

        &:last-of-type {
            margin-bottom: $global-margin*2;

            @include breakpoint(large) {
                padding-right: 5rem;
            }
        }
    }

    .btn {
        margin-top: $global-margin*1.5;
    }
}

.list-block-heading {
    font-size: $lead-font-size;
    font-style: italic;
    margin-bottom: $global-margin;
}

.list-block {
    list-style-type: none;
    margin: 0;
    display: block;

    li {
        padding: 1rem 0;
        border-top: 1px solid darken($sky-blue, 8%);
        position: relative;



        &:hover {

            .list-text svg {
                transform: translate(3px,0);
            }
        }



        .list-icon {
            overflow: visible;
            display: inline-block;
            @include vertical-center;
            top: 15%;
            transform: none;

            @include breakpoint(medium) {
                top: 50%;
                transform: translateY(-50%);
            }

            @include circle-fa($light-blue, $white);

            span {
                vertical-align: middle;
            }
        }

        .list-text {
            display: inline-block;
            margin-left: 65px;

            a {
                vertical-align: middle;
                display: inline-block;
                color: $dark-gray;
                transition: color .3s;

                svg {
                    width: 10px;
                    height: 10px;
                    margin-left: 6px;
                    transform: translate(0,0);
                    transition: transform .3s;
                }

                &:hover {
                    color: $offblack;
                }

                time {
                    font-size: $font-size-extra-small;
                    display: block;
                    margin-bottom: $global-margin/3;
                }
            }
        }
    }
}
