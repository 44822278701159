/// <reference path="../../app.scss" />

.search-tab-list {
    background-color: $sky-blue !important;
    list-style: none;
    padding: 0;
    margin: 0;
    border-color: $beige-gray;
    border-style: solid;
    border-width: 1px;
    border-top: 0;

    li {
        border-color: $beige-gray;
        border-style: solid;
        border-width: 0 1px 1px 0;
        background-color: $sky-blue;
        padding: 0;
        position: relative;

        @include breakpoint(large) {
            border-width: 0 1px 0 0;
        }

        &:last-child {
            float: left !important;
        }

        &:nth-child(6) {
            border: none;
        }

        svg {
            margin-right: 5px;
            width: 1em;
            height: 1em;
            position: relative;
            top: 1px;
        }

        a {
            display: block;
            width: 100%;
            padding: $global-padding/4 $global-padding/2;
            font-size: 11px;
            color: $black;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: uppercase;

            &.active {
                background-color: $beige-gray;
            }
        }

    }
}
