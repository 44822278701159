/// <reference path="../../app.scss" />

.qa-subject {
    font-weight: 400;
}

.qa-heading-row {
    margin-bottom: $global-margin/1.5;
    text-transform: uppercase;
    font-size: 12px;

    @include breakpoint(smaller) {
        //font-size: 12px;
    }

    > div {
        width: 100%;
        margin-bottom: 5px;


        @include breakpoint(smaller) {
            width: auto;
            margin-bottom: 0;
        }
    }

    .qa-sender {
        font-weight: bold;
        text-transform: capitalize;
    }
}

.qa-question {
    font-family: $heading-font-family;
    font-weight: 400;
    font-size: 16px;
    font-style: italic;
}
