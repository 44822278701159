/// <reference path="../../app.scss" />

.filter-part {
    height: 36px;
}

.outer-circle {
    height: 14px;
    width: 14px;
    max-width: 14px;
    border: 2px solid $offblack;
    border-radius: 50%;
    margin-right: 5px;
    top: 2px;
    position: relative;
    display: inline-block;

    .inner-dot {
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background-color: $dark-gray;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
        transform: scale(0);
        opacity: 0;
        transition: all .3s;
        transform: rotate(0) translate3d(0,0,0);
        backface-visibility: hidden;
    }
}

.outer-square {
    height: 14px;
    width: 14px;
    max-width: 14px;
    border: 1px solid $offblack;
    margin-right: 5px;
    top: 2px;
    position: relative;
    display: inline-block;

    .inner-square {
        height: 6px;
        width: 6px;
        background-color: $dark-gray;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
        transform: scale(0);
        opacity: 0;
        transition: all .3s;
        transform: rotate(0) translate3d(0,0,0);
        backface-visibility: hidden;
    }
}

.list-filter-section {
    margin-bottom: $global-margin*2;
    border: 1px solid $beige-gray;

    .filter-sub-heading {
        margin-bottom: 5px;
        font-size: $label-font-size;

        @include breakpoint(medium down) {
            margin-top: 10px;
        }

        &.selected-heading {
            text-transform: uppercase;
            font-weight: 500;
        }
    }

    .date-icon {
        position: relative;
        background-color: $offwhite;
        // max-width: 40px;
        border: 1px solid $gray;
        border-right: 0;
        cursor: pointer;

        .far {
            width: 1em;
            height: 1em;
            @include centerer;
        }
    }

    .date-picker-container {
    }

    input.list-filter-date {
        background-color: #FFF;
        font-size: 14px;
        margin-bottom: 0;
        border: 1px solid $gray;
        padding: 8px 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @include breakpoint(medium large) {
            margin-right: 15px;
        }


        @include breakpoint(large down) {
            font-size: 12px;
        }

        @include breakpoint(medium down) {
            font-size: 14px;
        }
    }

    .filter-radio {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        left: -9000px;

        &:checked + label {
            background-color: $beige-gray;
            //background-color: darken($sky-blue,10%);
            color: $black;

            .outer-circle {
                .inner-dot {
                    transform: scale(1);
                    opacity: 1;
                }
            }
        }
    }

    .filter-radio-label {
        background-color: $sky-blue;
        text-align: center;
        margin: 0;
        width: 100%;
        padding: 6px 0 5px;
        position: relative;
        transition: background-color .3s, color .3s;
        border: 1px solid $gray;
        color: lighten($dark-gray, 5%);
        text-transform: none;

        &.left {
            padding-left: 4px;
            border-radius: 20px 0 0 20px;
            border-right: 0;
        }

        &.center {
            border-right: 0;
        }

        &.right {
            padding-right: 5px;
            border-radius: 0 20px 20px 0;
        }
    }

    .box-content {
        form {
            > .row {
                margin: 0;
            }
        }
    }

    .cat-picker {
        position: relative;
        width: 100%;

        .cat-picked {
            padding: 6px 15px;
            padding-right: 40px;
            background-color: $sky-blue;
            border: 1px solid $gray;
            cursor: pointer;

            .multisel {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;

                .hida {
                    opacity: .7;
                    font-style: italic;
                }
            }

            &.active {
                .fa-angle-down {
                    transform: rotate(180deg);
                }
            }

            .fa-angle-down {
                position: absolute;
                font-size: 21px;
                height: 20px;
                right: 15px;
                top: 0;
                bottom: 0;
                margin: auto;
                transition: transform .3s;
                transform: rotate(0) translate3d(0,0,0);
                backface-visibility: hidden;
            }
        }

        .cat-picker-content {
            display: none;
            right: 0;
            left: 0;
            position: absolute;
            margin: 0;
            background-color: $sky-blue;
            border: 1px solid $gray;
            border-top: 0;
            list-style: none;
            z-index: 1;
            padding: 15px;
            padding-top: 13px;

            .cat-ok-btn {
                width: 100%;
                margin-top: $global-margin;
                cursor:pointer;
            }

            li {
                margin: 0;
                padding: 0;

                &:last-child {
                    .checkbox-label {
                    }
                }

                input {
                    margin-bottom: 0;
                }
            }
        }
    }

    .cat-column {
        @include breakpoint(medium only) {
            margin-left: -.9375rem;
            margin-top: .5rem;
        }
    }

    .row.collapse.filter-row {
        > div {
            @include breakpoint(medium) {
                padding-right: $global-padding;
            }

            &:nth-child(2n) {
                padding-right: 0;

                @include breakpoint(large) {
                    padding-right: $global-padding;
                }
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    .date-row {
        > div {
            &:first-child {
                @include breakpoint(medium) {
                    padding-right: $global-padding/4;
                }
            }

            &:last-child {
                @include breakpoint(medium) {
                    padding-left: $global-padding/4;
                }
            }
        }
    }

    .list-filter-submit {
        margin-top: $global-margin;
        padding-left: $global-padding/2;
        padding-right: $global-padding/2;
        width: 100%;
        cursor: pointer;

        .fa, .fas {
            margin-right: 2px;
        }

        @include breakpoint(medium) {
            width: auto;
        }

        @include breakpoint(xlarge) {
            padding-left: $global-padding;
            padding-right: $global-padding;
        }
    }

    .selected-filters {

        .clear-selected {
            background: $comp-green;
            line-height: $global-lineheight*1.5;
            padding: 0 $global-padding/2;
            border-radius: $button-border-radius;
            text-transform: uppercase;
            font-size: $small-font-size;
            transition: $button-transition;
            margin: 2px 4px 2px 0;
            cursor: pointer;

            @include breakpoint(medium down) {
                margin: 2px 4px 4px 0;
            }

            &:hover {
                background-color: darken($comp-green, 10%);
            }
        }

        .clear-filter {
            color: #03465C;
            cursor: pointer;
            //width: 100%;
            text-align: right;
            cursor: pointer;
            position: relative;
            margin-top: $global-margin;

            @include breakpoint(medium) {
                margin-top: 7px;
            }

            span {
                position: relative;

                &:after {
                    position: absolute;
                    bottom: -3px;
                    left: 0;
                    content: "";
                    width: 100%;
                    height: 1px;
                    background-color: $medium-gray;
                }
            }
        }
    }
}

ul.error-messages {
    list-style: none;
    margin: 0;
    margin-top: $global-margin;

    svg {
        color: $red;
    }
}
