/// <reference path="../../app.scss" />

/// Desktop/Large screen input type search style
/// @group comp
.search {
    display: block;
    margin-top: 10px;

    @include breakpoint(large) {
        display: inline-block;
        vertical-align: bottom;
    }

    .menu {
        float: right;
    }

    .animated-search-form[type=search] {
        width: 11rem;
        border: 1px solid $comp-green;
        border-radius: $button-border-radius;
        padding: .5rem 3.5rem .5rem 1rem;
        transition: width .4s;
        margin-left: $global-margin;

        &:focus {
            width: 16rem;
            box-shadow: none;
        }
    }

    .clear-input {
        right: 35px;
    }

    .button-search {
        padding: .7rem .6rem;
        cursor: pointer;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translate(0%,-50%);

        svg {
            color: #333;
        }
    }
}

.clear-input {
    display: none;
    font-size: 1.2em;
    user-select: none;
    cursor: pointer;
    top: 50%;
    right:0;
    transform: translate(-50%,-50%);
    position: absolute;
    font-size: 16px;
    color: $light-gray;

    @include breakpoint(large) {
        font-size:13px;
    }
}

.loading {
    display:none;
    top:35%;
    right: 55px;
    transform: translate(-50%,-50%);
    position: absolute;
    font-size: 16px;
    color: $light-gray;
}

/// Mobile input type search style
/// @group comp
#mobileSearch {
    position: relative;
    background: $sky-blue;
    width: 100%;
    padding: 10px 0;
    box-shadow: 1px 2px 2px rgba(0,0,0,.25);
    z-index: 98;
    display: none;

    @include breakpoint(large) {
        display: none !important;
    }

    li {
        vertical-align:bottom;

        &:first-child {
            width: 75%;
            position: relative;

            input[type=search] {
                border-color: $light-gray;
                border-style: solid;
                border-width: 0 0 1px 0;
                box-shadow: none;
                background: transparent;
                padding-right: 25px;
                width: 100%;
                box-sizing: border-box;
            }
        }
    }

    li .mobile-search-button {
        background: $dark-gray;
        border-radius: 100%;
        color: $white;
        width: 40px;
        height: 40px;
        position: relative;
        cursor: pointer;

        svg {
            @include centerer;
        }
    }
}
