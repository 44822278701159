/// <reference path="../util/custom-mixins.scss" />
@import 'util/custom-mixins';
@import 'settings.scss';


@media print {

    // header
    .nav-wrapper,
    .global-navigation,
    .header-navigation,

    // logo
    .website-logo:after,

    // beadcrumbs
    .breadcrumbs-nav,

    // blockareas
    .area.left-area,
    .area.right-area,
    .page-section.teasers,  

    // share view
    .area .article-footer,

    // footer
    .page-footer,

    // cookies
    .cookies
    {
        @include hide-when-print; 
    }


    .main-area {
        width: 100%;
        clear: both;
        padding: 0 5%;
        float: none;

        h1 {
            padding: 0;
            font-size: 1.8rem;
        }


        .article-main-area {
            width: 100%;
            clear: both;
            float: none;
            display: block;



            .article-content {

                h2 {
                    font-size: 1.4rem;
                }

                h3 {
                    font-size: 1.2rem;
                }

                h4 {
                    font-size: 1rem;
                }

                a {
                    border-bottom: none;

                    &:after {
                        color: gray;
                    }
                }

                blockquote {
                    border-width: 0 0 0 1px;
                    border-color: gray;
                }

                table, tbody, tr, td, th {
                    border-color: gray;
                }

                img {
                    margin: 0 0 20px;
                    max-width: 50%;
                    float: none !important;
                    display: block;
                    clear: both;
                }
            }
        }
    }
}
