/// <reference path="../../app.scss" />

/// Social media button style
/// @group btn
.socialmedia-btn {
    position: relative;
    display: inline-block;
    color: $offwhite;
    text-transform: uppercase;
    width: 100%;
    padding: 0 $global-padding/2;
    cursor: pointer;

    @include breakpoint(small) {
        width: auto;
    }

    @include breakpoint(medium) {
        margin-right: $global-margin/2;
        padding: 0;
    }
    /*@include breakpoint(medium) {
        margin-left: $global-margin*2;
        margin: $global-margin/2 $global-margin;
    }*/
    &:hover {
        color: inherit;

        .socialmedia-btn-icon {
            background-color: lighten($dark-gray, 15%);
        }
    }

    &.darker {
        &:hover {
            .socialmedia-btn-icon {
                background-color: lighten($offblack, 15%);
            }
        }

        .socialmedia-btn-icon {
            background-color: $offblack;
        }
    }

    &.facebook-btn {
        &:hover {
            .socialmedia-btn-icon {
                background-color: $facebook-color;
            }
        }

        .socialmedia-btn-icon {
            @include breakpoint(medium down) {
                background-color: $facebook-color;
            }
        }
    }

    &.twitter-btn {
        &:hover {
            .socialmedia-btn-icon {
                background-color: $twitter-color;
            }
        }

        .socialmedia-btn-icon {
            @include breakpoint(medium down) {
                background-color: $twitter-color;
            }
        }
    }

    &.linkedin-btn {
        &:hover {
            .socialmedia-btn-icon {
                background-color: $linkedin-color;
            }
        }

        .socialmedia-btn-icon {
            @include breakpoint(medium down) {
                background-color: $linkedin-color;
            }
        }
    }

    &.youtube-btn {
        &:hover {
            .socialmedia-btn-icon {
                background-color: $youtube-color;
            }
        }

        .socialmedia-btn-icon {
            @include breakpoint(medium down) {
                background-color: $youtube-color;
            }
        }
    }

    &.rss-btn {
        &:hover {
            .socialmedia-btn-icon {
                background-color: $rss-color;
            }
        }

        .socialmedia-btn-icon {
            @include breakpoint(medium down) {
                background-color: $rss-color;
            }
        }
    }

    .socialmedia-btn-icon {
        width: 36px;
        height: 36px;
        float: left;
        border-radius: 50%;
        background-color: $dark-gray;
        position: relative;
        box-shadow: 1px 1px 2px rgba(#000,.45);
        transition: background-color .2s;
        cursor: pointer;
        margin-right: 0;

        @include breakpoint(medium) {
            margin-right: 7px;
        }

        .fab, .fas {
            @include centerer;
            color: $offwhite;
            width: 1em;
            height: 1em;
        }
    }

    .socialmedia-btn-text {
        float: left;
        line-height: 30px;
        font-size: 10px;
        display: none;

        @include breakpoint(medium) {
            font-size: 13px;
            line-height: 36px;
            display: block;
        }
    }


    &.small {
        color: $black;
        //margin: $global-margin/2 0;
        /*@include breakpoint(medium) {
           // margin: $global-margin/2 0;
            margin-right: 0;
            padding-right: 0;
        }

        @include breakpoint(small) {
            //margin: $global-margin/3;
            //margin-bottom: 0;
            padding: $global-padding/2;
        }*/
        .socialmedia-btn-icon {
            width: 27px;
            height: 27px;
            margin-right: 7px;

            .fas {
                font-size: 13px;
            }
        }

        .socialmedia-btn-text {
            font-size: 10px;
            line-height: 31px;
            display: none;

            @include breakpoint(medium) {
                display: inline;
            }
        }
    }
}
