@import '../../settings.scss';
@import '../../util/custom-mixins';

ul.list-alfabetic {
    list-style: none;
    margin: 0;
    padding: 0;
    // Columns of li-list
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;

    @include breakpoint(smaller) {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }

    @include breakpoint(medium) {
        columns: 3;
        -webkit-columns: 3;
        -moz-columns: 3;
    }

    @include breakpoint(large) {
        columns: 4;
        -webkit-columns: 4;
        -moz-columns: 4;
    }
    /*@include breakpoint(xlarge) {
        columns: 5;
        -webkit-columns: 5;
        -moz-columns: 5;
    }*/
    li {
        margin-bottom: $global-margin/2;
        margin-right: $global-margin;
        padding-bottom: 0;

        &.list-alfabetic-heading {
            padding-top: $global-padding;
            border-bottom: 1px solid $medium-gray;

            &:first-child {
                padding:0;
            }

            .heading {
                font-family: $header-font-family;
                font-style: italic;
                font-weight: 700;
                color: $dark-gray;
            }
        }
    }
}

