/// <reference path="app.scss" />

/// Global body style
/// @group global
/// @require $heading-font-family
/// @require $base-font-size
body {
    font-family: $body-font-family;
    font-size: $base-font-size;
    color: $body-text-color;
    //background: red;
}

/// Global heading style
/// @group global
/// @require $heading-font-family
h1, h2, h3, h4, h5 {
    font-family: $heading-font-family;
    font-weight: 400;
}

svg {
    width: 0.75em;
    height: 0.75em;
    vertical-align: baseline;
    fill: currentColor;
}

#skip a {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

#skip a:focus {
    position: static;
    width: auto;
    height: auto;
}

:focus {
    outline: 0;
}

// Style for tabbed elements

@mixin tabbedStyle() {
    outline-style: solid;
    outline-width: 3px;
    outline-offset: 0px;
}
.tabbed {
    @include tabbedStyle();
}
.search .animated-search-form[type=search] {
    &.tabbed {
        @include tabbedStyle();
    }
}
.global-navigation {
    .tabbed {
        outline-offset: -5px;
    }
}

#footer {
    .tabbed {
        outline-color: #FFF;
    }
}

input[type='checkbox'] {

    &.tabbed {
        outline-offset: 3px;
    }
}

input[type='text'], input[type='date'], input[type='email'], .list-filter-submit {
    &.tabbed {
        outline-style: solid;
        outline-width: 3px;
        outline-offset: 3px;
        outline-color: $primary-color;
    }
}

// Artical fonts
.main-area {
    transition: font-size .2s;

    h1 {
        margin-bottom: $global-margin*1.5;
        line-height: 1.2;
        font-size: 1.6rem;

        @include breakpoint(medium only) {
            font-size: 1.8rem;
        }

        @include breakpoint(large) {
            font-size: 2.2rem;
        }
    }

    .article-content {

        h2, h3, h4 {
            padding-top: $global-padding;
            font-style: italic;
            //font-weight: bold;
            line-height: 1.3;
        }

        h2 {
            font-size: 1.5rem;
        }

        h3 {
            font-size: 1.3rem;
        }

        h4 {
            font-size: 1.2rem;
        }

        p {
            margin-bottom: $global-margin/1.5;
            line-height: 1.7;

            &.intro-p {
                font-weight: 400;
                font-size: 1.1rem;
                line-height: 1.6;
            }
        }

        .intro-p-container {
            p {
                font-weight: 400;
                font-size: 1.1rem;
                line-height: 1.6;
            }
        }

        blockquote {
            border-left: $blockquote-border;
            padding: $blockquote-padding;
            padding-top: 0;
            color: $blockquote-color;
            font-family: $heading-font-family;
            font-style: italic;
            font-size: $base-font-size;
        }


        ul, ol {
            margin-bottom: $global-margin/1.5;

            li {
                padding-left: $global-padding/4;
                margin-bottom: $global-padding/3;
            }
        }

        a {
            font-family: $body-font-family;
            border-bottom: 1px solid $comp-green;
            transition: border .2s;

            &:hover {
                border-bottom: 1px solid darken($comp-green, 10%);
                color: $dark-green;
            }
        }

        img {
            &.image-align-full-width {
                width: 100%;
            }

            &.image-align-left {
                margin: 0 10px 10px 0;
            }

            &.image-align-right {
                margin: 0 0 10px 10px;
            }

            @include breakpoint(small only) {
                width: 100%;

                &.image-align-full-width,
                &.image-align-left,
                &.image-align-right {
                    margin: 0;
                }
            }
        }

        table {
            border-color: $light-gray;

            tbody, tfoot, thead {
                border-color: $light-gray;
                font-size: 13px;
            }

            tr {
                border: inherit;
            }

            th {
                background: $gray;
                color: $white;
            }
        }
    }


    p {
        &.intro-p {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.6;
        }
    }
}



// Global settings

/// Global wrapper style
/// @group global
.wrapper {
    max-width: 1200px;
    margin: 0 auto;
}

.body-content {
    margin-top: $global-margin*2;
    padding: 0 0 $global-padding*2 0;

    @include breakpoint(large) {
        padding: 0 0 $global-padding*3 0;
    }
}

body {
    padding-top: 60px;

    @include breakpoint(large) {
        padding-top: 0;
    }


    &.startpage {
        .body-content {
            margin: 0;
            padding: 0;
        }
    }

    &.articlepage, &.guidelinearticlepage, &.newspage, &.eventpage {
        .body-content {
            padding-bottom: 0;
        }
    }
}

.row {

    &.collapse {
        margin-left: -.625rem;
        margin-right: -.625rem;
    }
}

.columns {
    &.nopadding {
        padding-left: 0;
        padding-right: 0;
    }

    &.nopadding-left {
        padding-left: 0;

        @include breakpoint(smaller down) {
            padding-right: 0;
        }
    }

    &.nopadding-right {
        padding-right: 0;

        @include breakpoint(smaller down) {
            padding-left: 0;
        }
    }
}

// Utility classes

.no-padding {
    padding: 0 !important;
}

// Settings forms
label {
    font-size: $form-label-font-size*0.9;
    text-transform: uppercase;
    line-height: $form-label-line-height*1.1;
}

// Settings navigation
.navigation {
    color: $white;
    background-color: #00445b;
    position: relative;

    ul {

        li {
            a {
                color: $white;
                padding: 15px 35px;
                text-transform: uppercase;
            }
        }
    }
}

//IE10 native clear icon remove fix.
.global-search::-ms-clear {
    width: 0;
    height: 0;
}

//Fix for IOS input zoom.

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
    font-size: 16px;

    @include breakpoint(large) {
        font-size: $base-font-size;
    }
}

.top-bar {
    background: $sky-blue;

    @include breakpoint(large) {
        background: $primary-color;
    }


    ul {
        background: transparent;
        color: $white;

        li {
            a {
                color: $white;
            }
        }
    }
}


// Overlay
.overlay {
    position: fixed;
    background: $sky-blue;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 3;

    &.active {
        height: 120%;

        @include breakpoint(large) {
            height: 0;
        }
    }
}


// Map
#map {
    width: 100%;
    height: 400px;

    #infowindow-content {
        display: inline;
    }
}

#geolocationData {
    margin: 40px 0 0 0;
    list-style-type: none;

    li {
        padding-bottom: 4px;
    }
}

#infowindow-content .title {
    font-weight: bold;
}

#infowindow-content {
    display: none;

    span {
        display: block;
    }

    #place-name {
        font-size: 15px;
        margin-bottom: 5px;
    }

    #place-address {
    }

    #large-map {
        margin-top: 5px;
        font-size: 13px;
        font-weight: bold;

        a:hover {
            text-decoration: underline;
        }

        svg {
            margin-left: 4px;
            vertical-align: middle;
        }
    }
}

.clickable-area {
    cursor:pointer;
}

.article-teaser, .page-footer {
    a {
        &:hover {
            text-decoration: underline;
        }
    }
}
