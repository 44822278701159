﻿/// <reference path="../app.scss" />

.related-content {
    background: $sky-blue;
    margin-bottom: 0 !important;
    margin-top: $global-margin;
    padding: 0 0 $global-padding 0;

    @include breakpoint(medium) {
        margin-top: $global-margin*2;
    }

    .related-header {
        display: inline-block;
        background: $sky-blue-lighter;
        padding: $global-padding/3 $global-padding*1.5 $global-padding/2 $global-padding*1.5;
        margin-bottom: $global-margin;
        font-size: $font-size-small;
        float: left;
        color: $dark-gray;
        font-weight: bold;
    }
}
